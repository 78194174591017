import { useEffect, useState } from "react";
import axios from 'axios'
import "./App.css";

const prodUrl = 'https://app.10point.ai/editorapi'


function authHeaderGet() {
  // return authorization header with jwt token
  // const { token } = JSON.parse(localStorage.getItem('state')).userProfile;
  const token = '';

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  return {};
}

const getProd = (requestBody = null, endpoint) => {
  // requestBody must be an object
  return axios
    .get(prodUrl + endpoint, { params: requestBody, headers: authHeaderGet() })
    .then((result) => result.data)
    .catch((err) => {
      console.error(`The error is ${err}`);
      // history.push('/error');
    });
};

function App() {

  const [totalPaid,setTotalPaid] = useState('')
  const [dau,setDau] = useState('')
  const [totalSignups,setTotalSignups] = useState('')

  useEffect(() => {
    getProd(null, '/getInvestorDashboardData').then((data) => {
      setTotalSignups(data.result['totalSignups'])
      setDau(data.result['dau'])
      setTotalPaid(342 + +data.result['totalPaid'])
    });
    }, [])
 
  return (
    <div className="App">
      <div className="logodiv">
        <img src='https://s3.ap-south-1.amazonaws.com/10point.ai/utilities/logo.png' alt='10point.ai'/>
      </div>
      <table>
      <tr className="trow">
          <td className="tcol">Total Signups</td>
          <td className="tcol">{totalSignups}</td>
        </tr>
        <tr className="trow">
          <td className="tcol">Daily Active Users</td>
          <td className="tcol">{dau}</td>
        </tr>
        
        <tr className="trow">
          <td className="tcol">Total Subscriptions</td>
          <td className="tcol">{totalPaid}</td>
        </tr>
      </table>
    </div>
  );
}

export default App;
